<template>
  <el-dialog
    title="推送银行"
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
    :before-close="handleCancel"
    class="br-dialog push-bank-dialog"
    @close="handleCancel"
  >
    <el-form ref="Forms" :model="dialogFrom" :rules="rules" label-width="120px" :inline="true">
      <el-form-item class="br-form-item-label" label="推送银行：" prop="bankObj">
        <el-select
          v-model="dialogFrom.bankObj"
          placeholder="请选择推送银行"
          clearable
          class="br-select"
          value-key="funderUnicode"
          @change="bankChange"
        >
          <el-option
            v-for="(item,index) in bankList"
            :key="index"
            :value="item"
            :label="item.funderName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="关联核心企业：" prop="coreCmpUnicode">
        <el-select
          v-model="dialogFrom.coreCmpUnicode"
          placeholder="请选择关联核心企业"
          clearable
          class="br-select"
          @change="coreEnterpriseChange"
        >
          <el-option
            v-for="(item,index) in coreEnterPriseList"
            :key="index"
            :value="item.cmpUnicode"
            :label="item.cmpName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="集团模式：" prop="groupType">
        <el-radio-group v-model="dialogFrom.groupType" class="br-radio" @change="groupTypeChange">
          <el-radio :label="1">
            是
          </el-radio>
          <el-radio :label="0">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="所属集团：" prop="groupName">
        <el-input v-model.trim="dialogFrom.groupName" class="br-input" disabled />
      </el-form-item>
      <el-form-item class="br-form-item-label" label="银行商圈编码：" prop="fundBusiNo">
        <el-select
          v-model="dialogFrom.fundBusiNo"
          placeholder="请选择银行商圈编码"
          filterable
          class="br-select"
          @change="fundBusiNoChange"
        >
          <el-option
            v-for="(item,index) in fundBusiNoList"
            :key="index"
            :value="item.fundBusiNo"
            :label="item.fundBusiNo"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="银行产品编码：" prop="bankProId">
        <el-select
          v-model="dialogFrom.bankProId"
          placeholder="请选择银行产品编码"
          filterable
          class="br-select"
          @change="bankProIdChange"
        >
          <el-option
            v-for="(item,index) in bankProList"
            :key="index"
            :value="item.fundProdNo"
            :label="item.fundProdNo"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="版本号：" prop="bankVersion">
        <el-select v-model="dialogFrom.bankVersion" placeholder="请选择版本号" filterable class="br-select" disabled>
          <el-option
            v-for="(item,index) in bankVersionList"
            :key="index"
            :value="item.dictId"
            :label="item.dictName"
          />
        </el-select>
      </el-form-item>
      <div class="tips-box">
        温馨提示:  1.请先配置核心企业商圈；2.供应商完成激活状态；3.关联核心企业与供应商关系。
      </div>
      <div class="title">
        可推送供应商列表
      </div>
      <div class="search-box">
        <el-form-item label="企业名称" prop="cmpName">
          <el-autocomplete
            v-model="dialogFrom.cmpName"
            clearable
            class="inline-input"
            :fetch-suggestions="queryCmpNameSearch"
            placeholder="请输入企业名称"
            :trigger-on-focus="false"
            @select="cmpNameSelect"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="br-btn template-search-btn" type="primary" size="small" @click="search">
            查询
          </el-button>
        </el-form-item>
      </div>
      <div class="table-box">
        <el-table
          ref="cmpTable"
          v-loading="btnloading"
          empty-text="暂无数据"
          :data="tableList.pageData"
          border
          @current-change="radioTableChange"
          @row-click="chooseOne"
        >
          <!--          <el-table-column type="selection" width="55"></el-table-column>-->
          <el-table-column label="选择" width="65">
            <template slot-scope="scope">
              <el-radio v-model="templateRadio" :label="scope.row.cmpUnicode" style="margin-left: 10px;">
                    &nbsp;
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="cmpName" />
          <el-table-column label="统一信用代码" prop="cmpUnicode" />
        </el-table>
      </div>
      <el-pagination
        v-if="tableList.total>0"
        class="br-pagination"
        :current-page="tableList.pageNum"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="tableList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableList.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-form>

    <div slot="footer" class="br-dialog-footer">
      <el-button class="br-btn-large br-cancel-btn" size="small" @click="handleCancel">
        取消
      </el-button>
      <el-button type="primary" class="br-btn-large br-submit-btn" :loading="btnloading" size="small" @click="handleSubmit">
        提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { selectFundcmp, selectGroupList, selectBankProList, selectPushCmpList, addPushCmp } from '@/api/memberManagement'
export default {
  props: {
    isShowDialog: {
      type: Boolean,
      default: () => (false)
    }
  },
  data() {
    return {
      bankList: [],
      dialogVisible: false,
      dialogFrom: {
        bankObj: '',
        groupType: 0,
        bankCode: '',
        fundCmpUnicode: '',
        fundCmpName: '',
        coreCmpUnicode: '',
        bankProId: '',
        fundBusiNo: '',
        bankVersion: '',
        cmpName: '',
        cmpUnicode: ''
      },
      rules: {
        bankObj: [
          {
            required: true,
            trigger: ['blur'],
            message: '选择推送银行'
          }
        ],
        coreCmpUnicode: [
          {
            required: true,
            trigger: ['blur'],
            message: '选择关联核心企业'
          }
        ],
        bankProId: [
          {
            required: true,
            trigger: ['blur'],
            message: '请选择银行产品编码'
          }
        ],
        fundBusiNo: [
          {
            required: true,
            trigger: ['blur'],
            message: '请选择银行商圈编码'
          }
        ]
      },
      btnloading: false,
      tableList: { pageData: [], total: 0, pageSize: 10, pageNum: 1 },
      templateSelection: {},
      templateRadio: false,
      bankProList: [],
      fundBusiNoList: [],
      searchParams: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    pushBankTypeList() {
      return this.$store.getters.getDictionaryItem('PUSH_BANK_TYPE')
    },
    intersTypeList() {
      return this.$store.getters.getDictionaryItem('INTERS_TYPE')
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    }
  },
  watch: {
    isShowDialog(newV) {
      this.dialogVisible = newV
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      selectFundcmp(res => {
        this.bankList = res.data
      })
      // 核心企业
      if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
        this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
      }
    },
    // 推送银行
    bankChange(e) {
      if (e) {
        this.dialogFrom.bankCode = e.bankCode
        this.dialogFrom.fundCmpUnicode = e.funderUnicode
        this.dialogFrom.fundCmpName = e.funderName
        // this.selectCoreList()
      }
    },
    // 关联核心企业
    coreEnterpriseChange(e) {
      this.dialogFrom['coreCmpName'] = this.coreEnterPriseList.filter(item => {
        return item['cmpUnicode'] === e
      })[0]['cmpName']
      if (this.dialogFrom.bankCode && e) {
        this.selectBankProList()
      }
    },

    radioTableChange(val) {
      if (val) { this.templateRadio = val.cmpUnicode }
      this.templateSelection = val
    },
    selectBankProList() {
      this.dialogFrom.groupType = 0
      this.dialogFrom.groupName = ''
      this.dialogFrom.groupCmpUnicode = ''
      this.dialogFrom.bankProId = ''
      this.dialogFrom.fundBusiNo = ''
      this.dialogFrom.bankVersion = ''
      this.tableList = { pageData: [], total: 0, pageSize: 10, pageNum: 1 }
      this.searchParams = {
        pageNum: 1,
        pageSize: 10
      }
      this.fundBusiNoList = []// 银行商圈编码
      this.bankProList = []// 银行产品编码
      const param = {
        fundCmpUnicode: this.dialogFrom.fundCmpUnicode,
        coreCmpUnicode: this.dialogFrom.coreCmpUnicode
      }
      selectBankProList(param, res => {
        if (res.code === 200) {
          this.fundBusiNoList = res.data// 银行商圈编码
        }
      })
    },
    // 分页-一页多少条变化
    handleSizeChange(pageSize) {
      this.searchParams.pageSize = pageSize
      this.search()
    },

    // 分页--换页
    handleCurrentChange(pageNum) {
      this.searchParams.pageNum = pageNum
      this.search()
    },
    handleCancel() {
      this.$refs['Forms'].resetFields()
      this.$emit('handleCancel', false)
    },
    handleSubmit() {
      this.formValidates(() => {
        if (this.templateRadio) {
          const params = {
            bankCode: this.dialogFrom.bankCode,
            coreCmpUnicode: this.dialogFrom.coreCmpUnicode,
            coreCmpName: this.dialogFrom.coreCmpName,
            cmpUnicode: this.templateRadio,
            groupCmpUnicode: this.dialogFrom.groupType ? this.dialogFrom.groupCmpUnicode : null,
            groupCmpName: this.dialogFrom.groupType ? this.dialogFrom.groupName : null,
            fundProdNo: this.dialogFrom.bankProId,
            fundBusiNo: this.dialogFrom.fundBusiNo,
            bankVersion: this.dialogFrom.bankVersion,
            fundCmpName: this.dialogFrom.fundCmpName,
            fundCmpUnicode: this.dialogFrom.fundCmpUnicode
          }
          addPushCmp(params, res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '提交成功'
              })
              this.handleCancel()
              this.$emit('getdata')
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: '请选择供应商'
          })
        }
      })
    },
    // 企业名称查询后选择回调
    cmpNameSelect(item) {
      this.dialogFrom.cmpUnicode = item.cmpUnicode
    },
    // 企业名称模糊查询
    queryCmpNameSearch(queryString, cb, t) {
      this.formValidates(() => {
        // const param = {
        //   cmpName: queryString,
        //   bankCode: this.dialogFrom.bankCode,
        //   coreCmpUnicode: this.dialogFrom.coreCmpUnicode,
        //   fundProdNo: this.dialogFrom.bankProId,
        //   bankVersion: this.dialogFrom.bankVersion,
        //   fundBusiNo: this.dialogFrom.fundBusiNo
        // }
      })
    },
    fundBusiNoChange(e) {
      this.dialogFrom.bankProId = ''
      this.dialogFrom.bankVersion = ''
      this.tableList = { pageData: [], total: 0, pageSize: 10, pageNum: 1 }
      this.searchParams = {
        pageNum: 1,
        pageSize: 10
      }
      this.bankProList = []// 银行产品编码

      const param = {
        fundCmpUnicode: this.dialogFrom.fundCmpUnicode,
        coreCmpUnicode: this.dialogFrom.coreCmpUnicode,
        fundBusiNo: e
      }
      // dialogFrom.groupType 1集团模式 0非集团模式
      if (this.dialogFrom.groupType === 1) {
        selectGroupList(param, res => {
          if (res.code === 200) {
            this.bankProList = res.data
            this.$forceUpdate()
          }
        })
      } else {
        selectBankProList(param, res => {
          if (res.code === 200) {
            this.bankProList = res.data// 银行产品编码
          }
        })
      }
    },
    groupTypeChange(e) {
      if (e) {
        setTimeout(() => {
          this.dialogFrom.bankProId = ''
          this.dialogFrom.fundBusiNo = ''
          this.dialogFrom.bankVersion = ''
          this.tableList = { pageData: [], total: 0, pageSize: 10, pageNum: 1 }
          this.dialogFrom.fundCmpUnicode && this.dialogFrom.coreCmpUnicode && this.selectGroupList({
            fundCmpUnicode: this.dialogFrom.fundCmpUnicode,
            coreCmpUnicode: this.dialogFrom.coreCmpUnicode
          })
          this.$forceUpdate()
        }, 300)
      } else {
        this.dialogFrom.bankCode && this.dialogFrom.coreCmpUnicode && this.selectBankProList()
      }
    },
    selectGroupList(params) {
      selectGroupList(params, res => {
        if (res.code === 200) {
          if (res.data.length <= 0 || !res.data[0].groupUnicode) {
            this.$message({
              type: 'error',
              message: '集团未配置商圈'
            })
            this.bankProList = []
            this.fundBusiNoList = []
            return
          }
          // this.bankProList = res.data;
          // this.fundBusiNoList = res.data
          if (res.data && res.data[0]) {
            this.dialogFrom.groupName = res.data[0].groupName
            //     this.dialogFrom.bankProId = res.data[0].fundProdNo;
            //     this.dialogFrom.fundBusiNo = res.data[0].fundBusiNo;
            //     this.dialogFrom.bankVersion = res.data[0].bankVersion;
            //     this.dialogFrom.groupCmpUnicode = res.data[0].groupCmpUnicode;
            //     this.search();
          }
          this.$forceUpdate()
        }
      })
    },
    bankProIdChange(e) {
      if (e) {
        this.dialogFrom.bankVersion = this.bankProList.filter(item => item['fundProdNo'] === e)[0]['bankVersion']
      }
      if (this.dialogFrom.bankCode && this.dialogFrom.coreCmpUnicode && this.dialogFrom.fundBusiNo && e) {
        this.search()
      }
    },
    chooseOne(row) {
      this.templateRadio = row.cmpUnicode
      this.templateSelection = row
    },
    search() {
      this.formValidates(() => {
        const params = {
          bankCode: this.dialogFrom.bankCode,
          coreCmpUnicode: this.dialogFrom.coreCmpUnicode,
          groupCmpUnicode: this.dialogFrom.groupType ? this.dialogFrom.groupCmpUnicode : null,
          cmpName: this.dialogFrom.cmpName,
          fundProdNo: this.dialogFrom.bankProId,
          bankVersion: this.dialogFrom.bankVersion,
          fundBusiNo: this.dialogFrom.fundBusiNo,
          ...this.searchParams
        }
        selectPushCmpList(params, res => {
          if (res.code === 200) {
            this.tableList = res.data
          }
        })
      })
    },

    formValidates(cb) {
      const $ele = this.$refs['Forms']
      $ele.validate(valid => {
        if (valid) {
          cb()
        } else {
          this.$message({
            type: 'error',
            message: '请选择推送银行和关联核心企业'
          })
          return false
        }
      })
    }
  }
}
</script>
